/**
 *  main.js
 *
 *  @project      **********
 *  @since        2013/12/08
 *  @version      0.0.2
 *  @author       TAMURA Yoshiya <a@qmu.jp>
 *  @requires     bootstrap.min.js
 *  @fileOverview 当サイトのメインJS
 */

"use strict";

(function (win, doc) {
  /**
   *  名前空間 : App
   *  このスクリプトに記述される処理は、
   *  全てこのオブジェクトのメンバとして記述し、
   *  唯一のグローバルオブジェクトとして扱う。
   *  @namespace App
   */
  var App = {
    Page   : {}, // URLが/hoge/fugaの場合App.Page.HogeFugaで自動的にロードされます
    Class  : {}, // クラスベースの実装はここに
    Const  : {}, // 定数などはここに
    Util   : {}  // その他の処理などはここに
  };

  /* -+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+ */

  /**
   *  定数
   *  @constant
   */
  // ホスト名
  App.Const.Host = "entry-core.kamimage.com";

  // URLのパターンとApp.Page.Klassのパターンの関連付け
  App.Const.URLPatterns = [{
    note    : 'ホーム画面',
    page    : 'Home',
    pattern : [
      /\/home.*$/
    ]
  }];

  /* -+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+ */

  /**
   *  jQueryの初期化ハンドラ
   *  ページ別に、Page以下のハンドラを実行する
   */
  $(function () {
    // ログ出力設定
    App.Util.Logger(App.Const.Host);

    // フォームの二重送信を防止
    App.Util.preventDoubleSubmit();

    // bootstrapのtooltipを初期化
    $('[data-toggle="tooltip"]').tooltip();

    // 一覧・検索画面の表示件数selectbox変更時
    $('.limit').change(function () {
      $('.limit').val($(this).val());
      this.form.submit();
    });

    // App.Page.HogeFugaと定義されるKlassを初期化
    // App.Const.URLPatternsのパターンに
    // マッチするページを初期化
    App.Util.pageKlassLoader(App.Const.URLPatterns);
  });

  /** ======================================================
   * App.Page
     ====================================================== */

  /**
   *  App.Page.Home
   *
   *  @class    ホーム画面
   *  @requires App.Util.EventDispatcher
   */
  App.Page.Home = function () {
    var self = new App.Util.EventDispatcher();

    /** ------------------------------
     *  Initialize
         ---------------------------------*/
    function init() {
      $('#order').change(function () {
        $('#formRoomCondition').submit();
      });
    }
    /** ------------------------------
     *  Private
         ---------------------------------*/

    /** ------------------------------
     *  Public
         ---------------------------------*/
    // ---------- init and export ----------
    init();
    return self;
  };

  /* -+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+ */

  /**
   *  App.Class.BootstrapTab
   * 
   *  @class    諸規定・書式のダウンロードモーダル
   *  @requires App.Util.EventDispatcher
   */
  App.Class.BootstrapTab = function () {
    var self = new App.Util.EventDispatcher();

    /** ------------------------------
     *  Initialize
         ---------------------------------*/
    function init() {
      var hash = window.location.hash,
        $tab = $('.nav-tabs:not(.just-style)'),
        $a =  $tab.find('a[href="' + hash + '"]'),
        $li = $a.parent();
      // アンカーで指定したタブを選択
      if (hash && $a.length > 0 && !$li.is('.disabled')) {
        $a.tab('show');
        $('body,html').animate({scrollTop:0}, 1);
      }
      // タブをクリックした時にアンカーに反映
      $tab.find('a').click(function (e) {
        e.preventDefault();
        var $clicked = $(this);
        if (!$clicked.parent().is('.disabled')) {
          $clicked.tab('show');
        }
        var t = $('body').scrollTop();
        window.location.hash = this.hash;
        $('html,body').scrollTop(t);
      });
    }
    /** ------------------------------
     *  Private
         ---------------------------------*/
    /** ------------------------------
     *  Public
         ---------------------------------*/

    // ---------- init and export ----------

    init();
    return self;
  };

  /** ======================================================
   * App.Util
     ====================================================== */

  /**
   *  App.Util.isMobile
   *  @function iPhone、Androidの場合true
   *  @return   {boolean} result
   */
  App.Util.isMobile = function () {
    var ua = navigator.userAgent.toUpperCase();
    if (/IPAD|IPHONE|ANDROID|MOBILE/g.test(ua)) {
      return true;
    }
    return false;
  };

  /* -+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+ */

  /**
   * App.Page.HogeFugaと定義されるKlassを初期化
   *
   * またApp.Const.url_patternsのパターンに
   * マッチするページを初期化する
   *
   * @function templateからDOM化
   * @return  {void}
   */
  App.Util.pageKlassLoader = function (url_patterns) {
    url_patterns = url_patterns || App.Const.URLPatterns;
    // url_patternsに指定されたURLパターンによる初期化
    var p = location.pathname,
      pttrns = url_patterns,
      i = 0, l = pttrns.length, j = 0, m = 0;
    for (; i < l; i++) {
      m = pttrns[i].pattern.length;
      for (j = 0; j < m; j++) {
        if (pttrns[i].pattern[j].test(p)) {
          win.log('Initialize', pttrns[i].page);
          new App.Page[pttrns[i].page]() ;
          return;
        }
      }
    }
    // 現在のpathnameから該当するApp.Page.Klassの初期化
    var items = p.split("/");
    if (items.length < 2) {
      return;
    }
    var controller_name = App.Util.camelize(items[1]),
      action_name = App.Util.camelize(items[2]),
      klass = App.Page[controller_name+action_name];
    if (klass) {
      new klass();
    }
  };

  /* -+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+ */

  /**
   * templateからDOM化
   *
   * @function templateからDOM化
   * @param   {string} selector
   * @param   {object} args
   * @return  {string} $el
   */
  App.Util.template = function (selector, args) {
    var tmpl = $(selector).html(),
      $el = $(_.template(tmpl, args)(args));
    return $el;
  };

  /* -+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+ */

  /**
   * App.Util.preventDoubleSubmit
   *
   * @function フォームの二重送信を防止
   * @param  {jQuery|string} selector
   * @return void
   */
  App.Util.preventDoubleSubmit = function (selector) {
    selector = selector || "form";
    var $form = typeof selector === "string" ? $(selector) : selector;
    $form.submit(function () {
      if ($(this).hasClass('allowDoubleSubmit') == false) {
        $(this).submit(function () {
          return false;
        });
      }
    });
  };

  /* -+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+ */

  /**
   * App.Util.generateDate
   * @function ハイフン区切りの文字列をスラッシュに置き換えDate化
   * @param  {string} str_date
   * @return {Date} d
   */
  App.Util.generateDate = function (str_date) {
    var d = typeof str_date === "string" ? new Date(str_date.replace(/-/g, "/")) : new Date(str_date);
    return d;
  };

  /* -+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+ */

  /**
   * App.Util.getMoneyFormat
   * @function 引数の数値を通貨形式の文字列で返す
   * @param  {int} num
   * @return {string}
   */
  App.Util.getMoneyFormat = function (num) {
    var partNum = 3,
      mark = ",",
      newNum = num + "",
      lengNum = newNum.length,
      ltNum, rtNum;
    for (var i = lengNum; i > 0; i--) {
      if (i % partNum == 0 && i != lengNum) {
        ltNum = newNum.slice(0, -i);
        rtNum = newNum.slice(-i);
        newNum = ltNum + mark + rtNum;
      }
    }
    return "￥" + newNum;
  };

  /* -+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+ */

  /**
   * App.Util.getDateStr
   *
   * @function dateから文字列形式の日付を返す
   * @param  {Date}   date
   * @param  {string} sep  optional
   * @return {string} result
   */
  App.Util.getDateStr = function (date, sep) {
    sep = sep || "/";
    var y = date.getFullYear(),
      m = date.getMonth() + 1,
      d = date.getDate(),
      result;
    if (m < 10) {
      m = '0' + m;
    }
    if (d < 10) {
      d = '0' + d;
    }
    result = y + sep + m + sep + d;
    return result;
  };

  /* -+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+ */

  /**
   * App.Util.addFigure
   * @function 三桁カンマ区切りの通貨フォーマットの文字列を返す
   * @param {int} exp
   * @return {string}
   */
  App.Util.addFigure = function (exp) {
    var num = new String(exp).replace(/,/g, "");
    while (num != (num = num.replace(/^(-?\d+)(\d{3})/, "$1,$2"))) {
      // empty
    }
    return num;
  };

  /* -+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+ */

  /**
   *  App.Util.isIE
   *  @function jQuery 1.9以降のため$.browserが利用できないため、IE判定を実装
   *  @return   {boolean} result
   */
  App.Util.isIE = function () {
    var result = /*@cc_on!@*/false; // IEの場合true
    return result;
  };

  /* -+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+ */

  /**
   *  App.Util.camelize
   *  @function (スネークケースな)textを大文字キャメルケースにして返す
   *  @param    {string} text
   *  @return   {string} result
   */
  App.Util.camelize = function (text) {
    text = text || "";
    return text.replace(/(^.)|_([a-z])/ig, function (all, letter) {
      if (letter) {
        return letter.toUpperCase();
      } else {
        return all.replace("_", "").toUpperCase();
      }
    });
  };

  /* -+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+ */

  /**
   *  App.Util.EventDispatcher
   *  @class カスタムイベントを登録・通知するオブザーバークラス
   */
  App.Util.EventDispatcher = function (hostname) {
    var ed = new EventDispatcher();
    function dispatchEvent(typ, opt_evt) {
      ed.dispatchEvent(typ, opt_evt);
    }

    /** ------------------------------
     *  Private
         ---------------------------------*/
    function EventDispatcher() {
      var self = {};

      /**
       *  @param {string}   typ イベント名
       *  @param {?Object=} opt_evt 引数に渡すオブジェクト（オプション）
       *
       *  @return {void}
       */
      self.handlers = {};
      self.dispatchEvent = function (typ, opt_evt) {
        if (!typ) {
          throw new Error("INVALID EVENT TYPE " + typ);
        }

        var obj = this.handlers || (this.handlers = {}),
          arr = obj[typ] || [],
          evt = opt_evt || {},
          len, i, fnc;

        evt.type || (evt.type = typ);

        // handle specified event type
        for (i = 0, len = arr.length; i < len; i++) {
          (fnc = arr[i]) && fnc.call(this, evt);
        }

        // handle wildcard "*" event
        arr  = obj["*"] || [];
        for (i = 0, len = arr.length; i < len; i++) {
          (fnc = arr[i]) && fnc.call(this, evt);
        }
      };

      /**
       *  @param {string} typ イベント名
       *  @param {function(evt:Object):void} fnc ハンドラ
       *
       *  @return {void}
       */
      self.addEventListener = function (typ, fnc) {
        if (!typ) {
          throw new Error("INVALID EVENT TYPE " + typ + " " + fnc);
        }

        var obj = this.handlers || (this.handlers = {});
        ( obj[typ] || (obj[typ] = []) ).push(fnc);
      };

      /**
       *  @param {string} typ イベント名
       *  @param {function(evt:object):void} fnc ハンドラ
       */
      self.removeEventListener = function (typ, fnc) {
        if (!typ) {
          throw new Error("INVALID EVENT TYPE " + typ);
        }

        var obj = this.handlers || (this.handlers = {}),
          arr = obj[typ] || [],
          i = arr.length;

        while (i) {
          arr[--i] === fnc && arr.splice(i, 1);
        }
      };
      return self;
    }
    /**
     *  e.g. delegateMethod("addEventListener").from(instance);
     *
     *  @param {string}  methodName
     *  @return {{from: function}}
     */
    function delegateMethod(methodName) {
      /*--------------------------------------------
             PRIVATE
             --------------------------------------------*/
      var instance = null,
        context;
      /*--------------------------------------------
             PUBLIC
             --------------------------------------------*/
      /**
       *  @param {Object}  ins
       *  @param {Object} [opt_context]
       */
      function from(ins, opt_context) {
        if (!ins[methodName]) {
          throw new Error(ins + " has no method : " + methodName);
        }
        instance = ins;
        context = arguments.length >= 2 ? opt_context : ins;
        return method;
      }
      function method() {
        return instance[methodName].apply(context, arguments);
      }
      /*--------------------------------------------
             EXPORT
             --------------------------------------------*/
      return {
        from : from
      };
    }
    /*--------------------------------------------
         EXPORT
         --------------------------------------------*/
    return {
      dispatchEvent       : dispatchEvent,
      addEventListener    : delegateMethod("addEventListener").from(ed),
      removeEventListener : delegateMethod("removeEventListener").from(ed),
      ed :  ed
    };
  };

  /* -+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+-+.o*o.+ */

  /**
   *  App.Util.Logger
   *  @class デバッグ用のログ出力実装
   *  @param {string} hostname
   */
  App.Util.Logger = function (hostname) {
    var self = {};

    /** ------------------------------
     *  Initialize
         ---------------------------------*/
    function init() {
      setLog(hostname);
    }

    /** ------------------------------
     *  Private
         ---------------------------------*/
    /**
     *  開発環境(IE以外)のみログ出力するlog関数を実装
     *  (user_agentを見てIEの場合出力しない)
     *  @private
     *  @param {string} hostname
     *  @return void
     */
    function setLog(hostname) {
      win.log = function (label) {
        var env = location.href.indexOf(hostname) > -1 ? "prod" : "dev" ;
        if (env === "prod") {
          return;
        }
        var args = Array.prototype.slice.call(arguments, 1);
        if (!/String/.test(Object.prototype.toString.call(label))) {
          args.unshift(label);
          label = "DEBUGGING";
        }
        args.unshift("[" + label + "]");
        // IEの場合ログを出さない
        var isMSIE = /*@cc_on!@*/false; // IEの場合true
        if (isMSIE) {
          return;
        } else {
          console.log.apply(console, args);
        }
      };
    }
    /** ------------------------------
     *  Public
         ---------------------------------*/

    // ---------- init and export ----------

    init();
    return self;
  };

}(window, document));
